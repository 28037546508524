<template>
	<div>
		<multiselect
			v-model="selected"
			:options="options"
			label="text"
			track-by="value"
			:placeholder="$t('select')"
			:close-on-select="true"
			:clear-on-select="true"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			@input="handleInput($event)"
		>
			<template slot="selection" slot-scope="{values, search, isOpen}">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
			</template>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	export default {
		props: {
			value: {
				type: String,
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			}
		},
		created() {
			this.selected = this.setSelected(this.value, this.options)
		},
		data() {
			return {
				selected: null,
				options: [
					{value: 'registration', text: "Before Registration"},
					{value: 'student', text: "Registered Student"}
				],
			}
		},
		methods: {
			translateNSelected: translateNSelected,
			handleInput: handleInput,
			setSelected: setSelected
		}
	}
</script>
