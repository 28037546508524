<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validate != '' ? 'box-border-color':''"
            @input="handleInput"
            label="text"
            track-by="value"
            :multiple="false"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
            :searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
            <span slot="noResult">
                {{ $t('no_result') }}
            </span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="getErrors"
              v-html="getErrors">
        </span>
    </div>
</template>

<script>
    import PaymentTypeService from "@/services/PaymentTypeService";
    import qs from "qs";
    export default {
        props: {
            type: {
                type: String,
                default:null
            },
            bank_id: {
                type: Number,
                default:null
            },
            is_bank_required: {
                type: Boolean,
                default:null
            },
            currency:{
                type: String,
                default:null
            },
            is_currency_required: {
                type: Boolean,
                default: false
            },
            value: {
                type: Number,
                default: null
            },
            validate: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                selected: this.value,
                options: [
                    {value: null, text: this.$t('select')}
                ],
            }
        },
        methods: {
            getOptions() {
                let filter={
                    status:'a'
                };
                this.setOptions();
                if(this.type!=null){
                    filter.type=this.type;
                }
                if(this.bank_id!=null){
                    filter.bank_id=this.bank_id;
                } else if(this.is_bank_required) {
                    return;
                }
                if(this.currency!=null){
                    filter.currency=this.currency;
                } else if(this.is_currency_required) {
                    return;
                }
                const config = {
                    params: {
                        filter: filter,
                        sort: ('name'),
                        limit: -1
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };
                PaymentTypeService.getAll(config)
                               .then(response => {
                                   const data = response.data.data;
                                   data.forEach((item) => {
                                       this.options.push({
                                           value: item.id,
                                           text: item.name,
                                       });
                                   });
                                   if(this.value){
                                       this.selected = this.options.filter(c => c.value == this.value);
                                   }
                               });
            },
            handleInput(event) {
                if (event && event.value) {
                    this.$emit('input', event.value);
                }
                else {
                    this.$emit('input', null);
                }
            },
            setOptions(){
                this.options = [{value: null, text: this.$t('select') }];
                this.selected = null;
            },
        },
        watch: {
            type: {
                handler: function (val) {
                    if(val){
                        this.getOptions()
                    }
                    else {
                        this.setOptions()
                    }
                }
            },
            bank_id: {
                handler: function (val) {
                    if(val){
                        this.getOptions()
                    }
                    else {
                        this.setOptions()
                    }
                }
            },
            currency: {
                handler: function (val) {
                    if(val){
                        this.getOptions()
                    }
                    else {
                        this.setOptions()
                    }
                }
            },
            value: function (newValue) {
                if (this.options.length > 0) {
                    this.selected = this.options.filter(c => c.value == newValue);
                }
            },
        },
        computed: {
            getErrors() {
                return this.validate
            }
        },
        created() {
            this.getOptions();
        },
    };
</script>
